<template>
  <div class="background">
    <div class="Title">
      <div>
        <img :src="this.Choice.pic" alt="" class="head">
      </div>
      <div class="middle">
        <p class="title">{{ this.Choice.type1 }}</p>
        <div class="text" v-show="this.Choice.type1 === '研发工程'">
          <p>从工程角度剖析，即一项工程（或一个产品）从开始到结束，一般要经历研究、开发、设计、制造、</p>
          <p>运行、营销、管理、服务和报废等阶段。设计的工作范围已经扩大，一项工程的整个过程也是一项设</p>
          <p>计需要考虑的全部过程。</p>
        </div>

        <div class="text" v-show="this.Choice.type1 === '产品设计'">
          <p>每个由人类生产出来的具有使用价值的物品设计，设计的主体可以是实体的也可以是虚拟的，可以说</p>
          <p>对人类生产生活的设计都可以称之为产品设计。我们旨在从事的使用价值中给人们带来一种美的、</p>
          <p>便捷的体验，在生产和生活中得到愉悦的享受。</p>
        </div>

        <div class="text" v-show="this.Choice.type1 === '传播设计'">
          <p>传播设计旨在吸引、激发、创造欲望并激励人们响应信息，建立品牌或转移销售。从改变行为，到宣</p>
          <p>传信息，再到传播信息。服务包括战略性业务思维，使用市场研究、创造力、问题解决能力以及技术</p>
          <p>技能和知识，我们利用色彩理论、页面布局、版式和创建视觉层次结构完成设计过程。</p>
        </div>

        <div class="text" v-show="this.Choice.type1 === '空间设计'">
          <p>主要针对人类生活空间的设计，从简单的软装设计到复杂的建筑设计，从生活空间设计到公共空间设</p>
          <p>计，环境设计不仅仅是美化环境，也是对环境功能性的排布和整合。主要将美的体验注入到舒适的环境</p>
          <p>中，目的是为了让人们的生活空间更加包容和立体，满足舒适性的同时也获得文化和气质上的提升。</p>
        </div>

      </div>
    </div>
    <div class="bottom">
      <div class="Second-Type">
<!--        <p class="typeTitle">类别</p>-->
        <div class="typeChoice">
          <div v-for="(secondType,i) in secondTypeSet">
              <a @click=getInfo(secondType)>{{ secondType }}</a>
          </div>
        </div>
      </div>
      <div class="content">
<!--        <div>-->
          <div v-for="(item,i) in info" class="item">
            <!--跳转-->
            <div  @click="this.$router.push({path:'/CaseDetail',query: {caseId: item.id}})">
              <div class="pic">
                <img :src="item.pic" alt="" style="width: 3.95rem;  height: 2.1rem;">
              </div>
              <div class="name">
                <span>{{item.caseName}}</span>
                <div class="browse">
                  <i class="fa fa-eye" style="font-size:.18rem"></i>
                  <i>136</i>
                </div>
              </div>

            </div>
          </div>
<!--        </div>-->
      </div>
      <div style="clear: both"></div>
      <div style="height: 1rem"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Case",
  data(){
    return{
      Choice:{
        type1:"产品设计",
        type2:"全部",
        pic:'',
      },
      secondTypeSet:[],
      info:'',

    }
  },
  mounted() {
    this.Choice.type1 = this.$route.query.caseType;
    if(this.Choice.type1 === undefined){
      this.Choice.type1 = '产品设计';
    }
    this.getInfo('全部');
    this.getHeadPic();
  },
  methods:{
    getInfo(index){

      this.Choice.type2 = index;

      this.$http({
        method: 'post',
        url: '/showCasesByTypes',
        data: this.Choice
      }).then(res => {
          this.info = res.data;
          }
      )},
    getHeadPic(){
      this.$http({
        method: 'get',
        url: '/getHeaderPic',
        data: this.Choice
      }).then(res => {
            switch (this.Choice.type1){
              case '产品设计':
                this.secondTypeSet = ['全部','医疗设备','仪器设备','消费电子','交通出行','工业设备','机器人'];
                this.Choice.pic = res.data.caseProductDesignHeader;
                break;
              case '研发工程':
                this.secondTypeSet = ['全部','结构设计','自动化设计'];
                this.Choice.pic = res.data.caseRdEngineeringHeader;
                break;
              case '传播设计':
                this.secondTypeSet = ['全部','品牌vi','电商视觉','网站/APP','产品包装','Logo设计','画册设计','UI设计'];
                this.Choice.pic = res.data.caseCommunicationDesignHeader;
                break;
              case '空间设计':
                this.secondTypeSet = ['全部','商业空间','厂房改造','会展陈列','空间导航',];
                this.Choice.pic = res.data.caseInteriorDesignHeader;
                break;
            }
          }
      )},
  },
}
</script>

<style scoped>
.background{
  width: 19.2rem;
  /*height: 20.28rem;*/
  height: auto;
}

.head{
  width: 19.2rem;
  height:10.8rem ;
  background-size: 100% 100%;
}

.middle{
  width: 19.2rem;
  height: 3.4rem;
  padding-top: 1rem;
  color: black;
  background: url("../../images/case_middle_background.png");
  background-size: 100% 100%;
}
.middle .title{
  font-size: .36rem;
  font-weight: 500;
  margin-left: 4.17rem;
  margin-bottom: .25rem;
}

.middle .text{
  font-size: .18rem;
  font-weight: 300;
  margin-left: 4.17rem;
}

.bottom .Second-Type .typeTitle{
  font-size: .24rem;
  font-weight: 300;
  margin-bottom: .24rem;
}

.typeChoice{
  width: 100%;
  height: .26rem;
  font-size: .26rem;
  font-weight: 400;
  margin-bottom: .82rem;
}

.typeChoice div {
  float: left;
  height: .26rem;
  cursor: pointer;
  padding-left: .37rem;
  padding-right: .37rem;
  border-left: .01rem solid #b1b1b1b1;
  line-height: .26rem;
}

.typeChoice div:nth-of-type(1){
  margin-left: 3.3rem;
}

.typeChoice div:nth-last-child(1){
  border-right: .01rem solid #b1b1b1b1;
}

.typeChoice div a{
  display: inline-block;
  height: .26rem;
  text-align: center;
  line-height: .26rem;
  font-size: .22rem;
}


.bottom .content{
  width: 19.2rem;
  padding-left: 3.3rem;
}

.content .item{
  width: 3.95rem;
  height: 2.37rem;
  float: left;
  background-color: white;
  margin-bottom: .6rem;
  margin-right: .3rem;
}


.content .item .pic{
  width: 3.95rem;
  height: 2.1rem;
  margin-bottom: .1rem;
  cursor: pointer;
}

.content .item .name{
  position: relative;
  font-size: .18rem;
  font-weight: 500;
  margin-left: .24rem;
  cursor: pointer;
  border: .01rem solid transparent;
}

.content .item .name:hover{
  color: #ec414d;
}

.content .item .name .browse{
  position: absolute;
  top: 0;
  right: .37rem;
  height: .16rem;
  line-height: .16rem;
  margin-top: .03rem;
}

.content .item .name .browse .fa-eye{
  margin-right: .07rem;
}



.blank{
  height: 2rem;
  background-color: #ffffff;
}
</style>